import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";


import Ksis from "../../img/projects/ksis.webp";
import Tacs from "../../img/projects/tacs.webp";
import Tis from "../../img/projects/tis.webp";
import Sbmc from "../../img/projects/sbmc.webp";
import Gtc from "../../img/projects/gtc.webp";
import Mti from "../../img/projects/mti.webp";
import Phenom from "../../img/projects/phenom.webp";
import Wocman from "../../img/projects/wocman.webp";
import Escs from "../../img/projects/escs.webp";
import Ces from "../../img/projects/ces.webp";


const Testimonial = () => {
  const clients = [
    {
      img: Ces,
      url: "https://www.cardinalekandemseminary.com/",
      review:
        "Built a school management system for Cardinal Ekandem Seminary School, Uyo Nigeria - 2019",
    },
    
    {
      img: Escs,
      url: "https://www.efficientschools.org.ng/",
      review:
        "Built a school management system for Efficient Secondary Commercial School, Uyo Nigeria - 2019",
    },
    {
      img: Wocman,
      url: "https://www.wocmantech.com",
      review:
        "Contributed API in WOCMAN Technology App, Lagos Nigeria - 2020",
    },
    {
      img: Ksis,
      url: "https://www.kingsolomonschools.org.ng",
      review:
        "Built a school management system for King Solomon International Schools, Uyo Nigeria - 2022",
    },
    {
      img: Gtc,
      url: "https://www.gladtidingscollege.com.ng",
      review:
        "Built a school management system for Glad Tidings College, Uyo Nigeria - 2022",
    },
    {
      img: Mti,
      url: "https://www.moderntraininginstitute.org",
      review:
        "Built a school management system for Modern Training Institute, Uyo Nigeria - 2022",
    },
    {
      img: Tacs,
      url: "https://www.theapostolicchurchschools.org.ng",
      review:
        "Built a school management system for The Apostolic Church Schools, Uyo Nigeria - 2022",
    },
    {
      img: Tis,
      url: "https://www.teevesschools.com",
      review:
        "Built a school management system for Teeves International Schools, Uyo Nigeria - 2022",
    },
    
    {
      img: Sbmc,
      url: "https://www.sbmc.com.ng",
      review:
        "Built a school management system for St. Brain Model College, Uyo Nigeria - 2023",
    },

    {
      img: Phenom,
      url: "http://phenom.ng",
      review:
        "Built API for lesson APP for Phenom Organization, Uyo Nigeria - 2024",
    },

  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <a href={client.url} rel="noreferrer" target="_blank">
                <div className="testimonial1">
                  <img src={client.img} alt="" />
                  <span>{client.review}</span>
                </div>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
