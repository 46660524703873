import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import { themeContext } from "../../Context";

import Escs from "../../img/projects/escs.webp";
import Ces from "../../img/projects/ces.webp";
import Ksis from "../../img/projects/ksis.webp";
import Tacs from "../../img/projects/tacs.webp";
import Tis from "../../img/projects/tis.webp";
import Sbmc from "../../img/projects/sbmc.webp";
import Gtc from "../../img/projects/gtc.webp";
import Mti from "../../img/projects/mti.webp";
import Phenom from "../../img/projects/phenom.webp";
import Wocman from "../../img/projects/wocman.webp";

const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
         <a href="https://www.cardinalekandemseminary.com/" target="_BLANK"><img src={Ces} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
         <a href="https://www.efficientschools.org.ng/" target="_BLANK"><img src={Escs} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.wocmantech.com" target="_BLANK"><img src={Wocman} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
         <a href="https://www.kingsolomonschools.org.ng" target="_BLANK"><img src={Ksis} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.gladtidingscollege.com.ng" target="_BLANK"><img src={Gtc} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.moderntraininginstitute.org" target="_BLANK"><img src={Mti} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.theapostolicchurchschools.org.ng" target="_BLANK"><img src={Tacs} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.teevesschools.com" target="_BLANK"><img src={Tis} alt="" /></a>
        </SwiperSlide>

        <SwiperSlide>
          <a href="https://www.sbmc.com.ng" target="_BLANK"><img src={Sbmc} alt="" /></a>
        </SwiperSlide>
       
        <SwiperSlide>
          <a href="http://phenomtutors.gjengineer.com.ng" target="_BLANK"><img src={Phenom} alt="" /></a>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
