import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";
import Jengineers from "../../img/jengineers.png";
import LinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import Twitter from "@iconscout/react-unicons/icons/uil-twitter";
import Gitub from "@iconscout/react-unicons/icons/uil-github";

const Footer = () => {
  return (
    <div className="footer">
      <img src={Wave} alt="" style={{ width: "100%" }} />
      <div className="f-content">
       
        <img src={Jengineers} alt="" style={{ width: "40%", height: "40%" }} />
        <div className="f-icons">
          <a href="https://www.linkedin.com/in/justice-george-33a50b184" target="_BLANK"><LinkedIn color="white" size={"3rem"} /></a>
          <a href="https://twitter.com/JusticeGeo" target="_BLANK"><Twitter color="white" size={"3rem"} /></a>
          <a href="https://github.com/J-Engineers" target="_BLANK"><Gitub color="white" size={"3rem"} /></a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
